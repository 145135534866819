import { render, staticRenderFns } from "./CoursePreview.vue?vue&type=template&id=348810c6&scoped=true&"
import script from "./CoursePreview.vue?vue&type=script&lang=js&"
export * from "./CoursePreview.vue?vue&type=script&lang=js&"
import style0 from "./CoursePreview.vue?vue&type=style&index=0&id=348810c6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "348810c6",
  null
  
)

export default component.exports